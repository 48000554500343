import React, { useState } from 'react';
import Image from 'next/image';
import logo from '~/assets/logo.png';
import { useRouter } from 'next/router';

import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt } from '@fortawesome/pro-light-svg-icons';
import { customSocials } from '~/constants/socials';
import Link from 'next/link';
import { Modal } from 'antd';
import styles from './NewPublicLayout.module.scss';

type Props = {
    propertyTodayCount?: number;
    children: React.ReactNode;
};

const DemoButton = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Modal
                style={{ top: 20 }}
                bodyStyle={{
                    height: '82vh',
                    overflow: 'auto',
                }}
                visible={isOpen}
                destroyOnClose
                onCancel={() => setIsOpen(false)}
                footer={null}
                width={800}
                maskClosable
            >
                <iframe
                    width="100%"
                    height="100%"
                    src="https://realty.pipedrive.com/scheduler/WJ2qDWfJ/realtycomau-demo"
                    title="Realty.com.au Marketplace Demo"
                />
            </Modal>
            <button
                onClick={() => setIsOpen(true)}
                className={`flex items-center whitespace-nowrap rounded-lg bg-gray-900 py-3 text-sm font-semibold text-white duration-300 hover:cursor-pointer hover:bg-gray-900/80 sm:text-base ${styles.addYourListingButton}`}
                // className="w-fit whitespace-nowrap rounded-lg bg-blue-500 px-20 py-3 text-base font-semibold text-white duration-300 hover:bg-blue-400"
            >
                Demo
            </button>
        </>
    );
};

const NewPublicLayout = ({ propertyTodayCount, children }: Props) => {
    const router = useRouter();

    return (
        <div>
            <nav
                className="fixed top-0 z-50 flex w-full justify-center bg-white py-4 sm:py-5"
                style={{ boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)' }}
            >
                <div className="flex h-full w-full max-w-screen-xl items-center justify-between gap-x-4 px-5 md:px-16">
                    <div className="relative">
                        <Link href="/">
                            <Image src={logo} width={168} height={48} alt="Realty.com.au" className="object-contain" />
                        </Link>
                    </div>
                    <div className="flex items-center space-x-7">
                        {router?.pathname === '/' && (
                            <>
                                {propertyTodayCount > 0 && (
                                    <Link href="/discover">
                                        <a
                                            target="_blank"
                                            className="hidden whitespace-nowrap text-lg font-medium text-gray-900 hover:text-gray-900 sm:block"
                                        >
                                            New Listings Today: {propertyTodayCount?.toLocaleString()}
                                        </a>
                                    </Link>
                                )}
                                <Link href="/agency-sign-up">
                                    <span
                                        className={`h-[3.75rem]1 flex items-center whitespace-nowrap rounded-lg bg-gray-900 py-3 text-sm font-semibold text-white duration-300 hover:cursor-pointer hover:bg-gray-900/80 sm:text-base ${styles.addYourListingButton}`}
                                    >
                                        Add your listings
                                    </span>
                                </Link>
                            </>
                        )}
                        {router?.pathname === '/sign-up' && <DemoButton />}

                        {/* <div className="flex items-center duration-300 hover:text-primary-500"> */}
                        {/*    <FA icon={faEnvelope} className="mr-2" /> */}
                        {/*    <a className="text-black" href="mailto:marketplace@realty.com.au"> */}
                        {/*        marketplace@realty.com.au */}
                        {/*    </a> */}
                        {/* </div> */}
                    </div>
                </div>
            </nav>
            <main className="mt-[5.6875rem] sm:mt-[6.25rem]">{children}</main>
            <footer className="flex w-full flex-col items-center justify-center border-t  text-black">
                <div className="w-full max-w-screen-xl">
                    <div className="flex flex-col justify-start gap-6 px-5 py-12 md:flex-row md:justify-between md:px-16 md:py-16">
                        <div className="relative">
                            <Link href="/">
                                <Image
                                    src={logo}
                                    width={168}
                                    height={48}
                                    alt="Realty.com.au"
                                    className="object-contain"
                                />
                            </Link>
                        </div>
                        <div>
                            <span className="font-semibold">ON MARKET LISTINGS</span>
                            <ul className="mt-3 space-y-3 text-sm font-light">
                                <li className="duration-300 hover:text-primary-500">
                                    <Link href="/buy#search">
                                        <span className="cursor-pointer">For Sale</span>
                                    </Link>
                                </li>
                                <li className="duration-300 hover:text-primary-500">
                                    <Link href="/rent#search">
                                        <span className="cursor-pointer">For Rent</span>
                                    </Link>
                                </li>
                                <li className="duration-300 hover:text-primary-500">
                                    <Link href="/sold#search">
                                        <span className="cursor-pointer">Sold</span>
                                    </Link>
                                </li>
                                <li className="duration-300 hover:text-primary-500">
                                    <Link href="/contact-agent">
                                        <span className="cursor-pointer">Agents</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <span className="font-semibold">QUICK LINKS</span>
                            <ul className="mt-3 space-y-3 text-sm font-light">
                                <li className="duration-300 hover:text-primary-500">
                                    <Link href="/faq">
                                        <span className="cursor-pointer">FAQ</span>
                                    </Link>
                                </li>
                                <li className="duration-300 hover:text-primary-500">
                                    <Link href="/suburb-profile">
                                        <span className="cursor-pointer">Explore Suburbs</span>
                                    </Link>
                                </li>
                                <li className="duration-300 hover:text-primary-500">
                                    <Link href="/privacy">
                                        <span className="cursor-pointer">Privacy Policy</span>
                                    </Link>
                                </li>
                                <li className="duration-300 hover:text-primary-500">
                                    <Link href="/terms-of-service">
                                        <span className="cursor-pointer">Terms of Service</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <span className="font-semibold">CONTACT</span>
                            <ul className="mt-3 space-y-6 text-sm font-light">
                                {/* <li className="flex justify-start duration-300 hover:text-primary-500">
                                    <FA icon={faMapMarkerAlt} className="mr-2 mt-1" />
                                    <a
                                        className="text-black"
                                        href="//goo.gl/maps/kKhtBeu3FMR2"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        The Element Building Level 4
                                        <br />
                                        200 Central Coast Highway Erina NSW 2250
                                    </a>
                                </li> */}
                                <li className="flex items-center duration-300 hover:text-primary-500">
                                    <FA icon={faEnvelope} className="mr-2" />
                                    <a className="text-black" href="mailto:support@realty.com.au">
                                        support@realty.com.au
                                    </a>
                                </li>
                                <li className="flex items-center duration-300 hover:text-primary-500">
                                    <FA icon={faPhoneAlt} className="mr-2" />
                                    <a className="text-black" href="tel:1800 001 212">
                                        (AU) &nbsp; 1800 001 212
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <span className="font-semibold">SOCIAL</span>
                            <div className="mt-3 flex flex-wrap gap-6">
                                {customSocials.map(({ link, title, icon }) => (
                                    // img src={icon}
                                    <a
                                        className="h-6 w-6 text-black"
                                        key={link}
                                        href={`${link}`}
                                        title={`${title}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={icon}
                                            alt={title}
                                            className="h-full w-full object-contain hover:text-primary-500"
                                        />
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex h-[6.25rem] w-full items-center justify-center bg-[#1F1E1D] text-sm text-white">
                    <span>&copy; {new Date().getFullYear()} Realty.com.au. All rights reserved.</span>
                </div>
            </footer>
        </div>
    );
};

export default NewPublicLayout;
